import React, { useEffect, useState } from 'react';

import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';

import { useBasicInfo } from '@repositories/basicInfo';

import { useAppConfig } from '@hooks/appConfig';
import { useLogger } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

type Props = {
  Component: React.ElementType;
};

export enum Status {
  LOADING = 0,
  LEADER = 1,
  NOT_LEADER = 2,
}

export const AuthenticateOfficialDirectoryRoute: React.FC<Props> = ({
  Component,
}) => {
  const { addLog } = useLogger();
  const { user = { isLoggedIn: false, login: '' }, login } = useAppConfig();
  const [leaderStatus, setLeaderStatus] = useState<Status>(Status.LOADING);

  const officialDirectoryAppIds: string[] = [
    process.env.GATSBY_OFFICIAL_DIRECTORY_APP_ID || '',
  ];
  const hasAccess = (
    leaderApps: string[],
    response: DIS.GroupApplicationRolesRelationship[]
  ): boolean => {
    return response.some(user =>
      user.applications.some(app => leaderApps.includes(app.appInstanceId))
    );
  };

  const [basicInfo] = useBasicInfo();

  useEffect(() => {
    if (!user?.isLoggedIn) {
      addLog({
        level: LogLevel.Info,
        message: `User ${user?.login} navigated official-directory without login, redirecting to login page`,
      });
      login(true);
    }

    const checkOfficalDirectoryAccess = async () => {
      // Get Basic Info
      if (user?.login) {
        const basicUserInfo = await basicInfo({
          variables: {
            emailId: user?.login,
          },
        });

        const userInfo = (basicUserInfo?.data
          ?.basicInfo as unknown) as DIS.SSOTicketResponse;

        if (userInfo?.groupApplicationRolesRelationship?.length) {
          const isOfficialLeader = hasAccess(
            officialDirectoryAppIds,
            userInfo?.groupApplicationRolesRelationship
          );

          setLeaderStatus(isOfficialLeader ? Status.LEADER : Status.NOT_LEADER);
          addLog({
            level: LogLevel.Info,
            message: `Is user ${user?.login} official directory leader: ${isOfficialLeader}`,
          });
        }
      }
    };
    checkOfficalDirectoryAccess();
  }, [user?.isLoggedIn, user?.login]);

  if (user?.isLoggedIn) {
    if (leaderStatus === Status.LEADER) {
      addLog({
        level: LogLevel.Info,
        message: `Official Directory authentication for user ${user?.login} was successful.`,
      });
      return <Component />;
    }
    if (leaderStatus === Status.NOT_LEADER) {
      addLog({
        level: LogLevel.Info,
        message: `User ${user?.login} does not has access to view official directory.`,
      });
      return <AccessDenied />;
    }
  }
  return <Loading />;
};
