import * as React from 'react';
import { FC, useState } from 'react';

import RelatedLinks from '@rotaryintl/harris-related-links';
import Tabs from '@rotaryintl/harris-tabs';
import { TabInfo } from '@rotaryintl/harris-tabs/dist/tabs.props';

import ClubSection from '@presenters/web/components/OfficialDirectory/Club/ClubSection';
import DistrictSection from '@presenters/web/components/OfficialDirectory/District/DistrictSection';

import './styles.css';

import { useTranslation } from '@external/react-i18next';

import {
  LanguageCode,
  RelatedLink,
  RelatedLinkCommonAttr,
} from '@typings/official-directory';

const OfficialDirectory: FC = () => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language.toLowerCase() as LanguageCode;
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: 'District',
    scrollTo: 0,
  });

  const commonLinksAttribute: RelatedLinkCommonAttr = {
    LinkComponent: 'a',
    target: '_blank',
    withIcon: false,
  };

  const relatedLinks: RelatedLink[] = [
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.board-of-directors',
        'Board of directors'
      )}`,
      href: `https://www.rotary.org/${langCode}/about-rotary/our-leaders/directors`,
    },
    {
      ...commonLinksAttribute,
      children: `${t('official-directory.related-links.trustees', 'Trustees')}`,
      href: `https://www.rotary.org/${langCode}/about-rotary/our-leaders/trustees`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.rotary-international-committees',
        'Rotary International Committees'
      )}`,
      href: `https://my.rotary.org/${langCode}/news-media/office-president/rotary-international-committees`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.the-rotary-foundation-committees',
        'The Rotary Foundation Committees'
      )}`,
      href: `https://my.rotary.org/${langCode}/news-media/office-president/rotary-foundation-committees`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.executive-staff',
        'Executive staff'
      )}`,
      href: `https://www.rotary.org/${langCode}/about-rotary/our-leaders/executive-staff`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.rotary-action-groups',
        'Rotary Action Groups'
      )}`,
      href: `https://my.rotary.org/${langCode}/take-action/empower-leaders/rotary-action-groups`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.fellowships',
        'Fellowships'
      )}`,
      href: `https://www.rotary.org/${langCode}/our-programs/more-fellowships`,
    },
    {
      ...commonLinksAttribute,
      children: `${t(
        'official-directory.related-links.regional-leaders',
        'Regional Leaders'
      )}`,
      href: `https://my.rotary.org/${langCode}/regional-leaders-list`,
    },
  ];

  return (
    <div className="my-4 mobile-l:mx-10 desktop:mx-0 p-0 desktop:mb-40">
      <h1 className="text-xl-38 my-0 mobile-l:text-xl-48 desktop:text-xl-58 font-semibold leading-lg-45 mobile-l:leading-xl-57 desktop:leading-xl-70 text-naturals-gray-600">
        {t('official.directory.page-heading', 'Official Directory')}
      </h1>
      <div className="flex flex-col desktop:flex-row justify-between gap-4 desktop:gap-16 desktop:mt-2">
        <div className="m-0 p-0 desktop:w-7/12">
          <Tabs
            activeTabInfo={activeTab}
            onChangeTab={setActiveTab}
            tabs={[
              {
                component: <DistrictSection />,
                name: 'District',
                title: 'District',
              },
              {
                component: <ClubSection />,
                name: 'Club',
                title: 'Club',
              },
            ]}
          />
        </div>
        <div className="desktop:mt-2">
          <RelatedLinks
            description={t(
              'official.directory.related-links-description',
              'Read about key people who help shape your Rotary experience.'
            )}
            links={relatedLinks}
            title={t(
              'official.directory.related-links-title',
              'More of your Rotary network'
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default OfficialDirectory;
