export const scrollToPosition = (
  offset: number,
  targetRef: React.RefObject<HTMLDivElement>
) => {
  if (targetRef.current) {
    const topPosition =
      targetRef.current.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: topPosition, behavior: 'smooth' });
  }
};
