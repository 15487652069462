import * as React from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import OfficialDirectory from '@presenters/web/components/OfficialDirectory/OfficialDirectory';
import { AuthenticateOfficialDirectoryRoute } from '@components/Auth/AuthenticateOfficialDirectoryRoute';

const OfficialDirectoryPage: React.FC = () => {
  return (
    <OneColumn>
      <AuthenticateOfficialDirectoryRoute Component={OfficialDirectory} />
    </OneColumn>
  );
};
export default OfficialDirectoryPage;
