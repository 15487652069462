import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Alert from '@rotaryintl/harris-alert';
import Pagination from '@rotaryintl/harris-pagination';

import {
  DISTRICTS_CURRENT_PAGE,
  DISTRICTS_PAGE_SIZE,
  DISTRICTS_PAGINATION_VARIANT,
} from '@presenters/web/components/OfficialDirectory/constants';
import DistrictCardResults from '@presenters/web/components/OfficialDirectory/District/DistrictCardResults';
import NoResultFound from '@presenters/web/components/OfficialDirectory/Others/NoResultFound';

import LoadingComponent from '../Others/LoadingComponent';

import { scrollToPosition } from '@utils/scrollToPosition';

import { getOfficialDirectoryDistrictSearchByIdQuery } from '@queries/getOfficialDirectoryDistrictSearchByIdQuery';

import { useTranslation } from '@external/react-i18next';

import { OfficialDirectoryDistrictSearchByIdDocs } from '@typings/graphql';
import {
  District,
  DistrictCardProps,
  LanguageCode,
} from '@typings/official-directory';

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const DistrictCard: FC<DistrictCardProps> = ({ searchParams }) => {
  const { t, i18n } = useTranslation();

  const langCode = i18n.language.toLowerCase() as LanguageCode;

  // Result of search query.
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState<District[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const targetRef = React.useRef<HTMLDivElement | null>(null);

  const pageSize = DISTRICTS_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState<number>(
    DISTRICTS_CURRENT_PAGE // Defaults to 1.
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalCount);
  const firstIndex = startIndex + 1;

  // On search params change.
  useEffect(() => {
    // On search params reset.
    if (searchParams?.trim() === '') {
      setSearchData([]);
      setTotalCount(0);
      setCurrentPage(DISTRICTS_CURRENT_PAGE);
    } else {
      setLoading(true);
    }
  }, [searchParams]);

  // On page change.
  useEffect(() => {
    setLoading(true);
  }, [currentPage]);

  // Data fetching query.
  const { data } = useQuery(getOfficialDirectoryDistrictSearchByIdQuery, {
    variables: {
      input: {
        query: searchParams ? `DistrictId:${searchParams}*` : '',
        fl: '* ,score',
        start: String(startIndex),
        sort: 'score desc, DistrictId asc',
        rows: String(pageSize),
        wt: 'json',
      },
    },
  });

  const prevSearchData = usePrevious(searchData);
  // Data Transformer
  useEffect(() => {
    if (data?.officialDirectoryDistrictSearchById?.response?.docs?.length) {
      const tempData = data?.officialDirectoryDistrictSearchById?.response?.docs?.map(
        (district: OfficialDirectoryDistrictSearchByIdDocs) => ({
          id: district.id,
          districtNumber: district.DistrictId,
          country: district.DistRepAddressCountry,
          legalDesc: district.LegalDescription,
          zone: district.Zone,
          occupationTitle: district.DistRepRole,
          name:
            (district.DistRepFirstName
              ? `${district.DistRepFirstName}, `
              : '') +
            (district.DistRepLastName ? `${district.DistRepLastName}` : ''),
          address:
            (district.DistRepAddressLine1
              ? `${district.DistRepAddressLine1}, `
              : '') +
            (district.DistRepAddressLine2
              ? `${district.DistRepAddressLine2}, `
              : '') +
            (district.DistRepAddressLine3
              ? `${district.DistRepAddressLine3}, `
              : '') +
            (district.DistRepAddressPostalCode
              ? `${district.DistRepAddressPostalCode}, `
              : '') +
            (district.DistRepAddressCountry
              ? `${district.DistRepAddressCountry}`
              : ''),
          phoneNumber: district.DistRepPhoneNumber
            ? (district.DistRepPhoneCountryCode
                ? `+ ${district.DistRepPhoneCountryCode} `
                : '') + district.DistRepPhoneNumber
            : '',
          workNumber: district.DistRepFaxNumber
            ? (district.DistRepFaxCountryCode
                ? `+ ${district.DistRepFaxCountryCode} `
                : '') + district.DistRepFaxNumber
            : '',
          emailId: district.DistRepEmailAddress,
          spousePartner: `${district.DistRepSpouseFirstName ||
            ''} ${district.DistRepSpouseLastName || ''}`,
          classification: district.DistRepClassification || '',
        })
      );

      if (prevSearchData !== tempData) {
        // Set data to state variable.
        setSearchData(tempData);
        setTotalCount(
          data?.officialDirectoryDistrictSearchById?.response?.numFound || 0
        );

        if (tempData.length > 0) {
          setExpandedCardId(tempData[0].id);
        }

        setLoading(false);
      } else {
        // TODO: Verify.
      }
    } else if (
      data &&
      !data?.officialDirectoryDistrictSearchById?.response?.docs?.length
    ) {
      setLoading(false);
    }
  }, [data]);

  const handleExpand = (id: string) => {
    setExpandedCardId(prevId => (prevId === id ? null : id));
  };

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToPosition(350, targetRef);
  };

  if (loading) return <LoadingComponent />;

  // TODO: Look again.
  if (!loading && !searchData?.length) {
    return <NoResultFound searchParams={searchParams} />;
  }

  return (
    <div>
      <div className="py-2">
        <p className="text-xs">
          {t(
            'official-directory-pagination-title',
            'Showing <strong> {{firstIndex}} </strong> - <strong> {{endIndex}} </strong> of  <strong> {{totalCount}} </strong>  results for {{searchParams}}',
            { firstIndex, endIndex, totalCount, searchParams }
          )}
        </p>
      </div>
      <div className="my-0">
        <Alert variant="info" isDismissible={false}>
          {t(
            'official.directory.results-alert',
            `Please visit the club website or contact the club directly to confirm
          meeting details. Information submitted to Rotary International may not
          be reflected immediately in search results.`
          )}
        </Alert>
      </div>
      <div ref={targetRef} />
      <div>
        {searchData?.length &&
          searchData.map(district => (
            <DistrictCardResults
              key={district.id}
              district={district}
              searchData={searchData}
              expandedCardId={expandedCardId}
              onClick={handleExpand}
            />
          ))}
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          lang={langCode}
          onPageChange={onPageChange}
          pageSize={pageSize}
          siblingCount={DISTRICTS_CURRENT_PAGE}
          totalCount={totalCount || 0}
          variant={DISTRICTS_PAGINATION_VARIANT}
        />
      </div>
    </div>
  );
};

export default DistrictCard;
